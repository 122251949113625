import * as React from "react";
import { __ } from "../../../utilities";
import { useAuth } from "@sportnet/auth-react";
import BasicTable from "@sportnet/ui/BasicTable";
import Input from "@sportnet/ui/Input";
import Paginator from "@sportnet/ui/Paginator";
import PropTypes from "prop-types";
import Segment from "@sportnet/ui/Segment";
import apiEshop from "../../../service/apiEshop";

const LIMIT = 20;

const ProductsSorter = ({ saleschannel, categoryId }) => {
  const { ppo } = useAuth();
  const [offset, setOffset] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [products, setProducts] = React.useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = React.useState(false);
  const [isSubmittingById, setIsSubmittingById] = React.useState({});

  const fetchProducts = React.useCallback(() => {
    setIsFetchingProducts(true);
    try {
      apiEshop
        .saleschannelSearchProducts(ppo, saleschannel, {
          limit: LIMIT,
          offset,
          categories: [categoryId],
        })
        .then((res) => {
          setProducts(res.products || []);
          setTotal(res.total || 0);
        });
    } catch (e) {
      alert(__("Nepodarilo sa získať zoznam produktov"));
    } finally {
      setIsFetchingProducts(false);
    }
  }, [ppo, offset, categoryId, saleschannel]);

  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleChangePriority = (item, value) => {
    const items = [...products];
    const productIdx = products.findIndex((i) => i._id === item._id);
    const priorityIdx = (products[productIdx].categoryPriority || []).findIndex(
      (cItem) => cItem.categoryId === Number(categoryId),
    );
    if (priorityIdx > -1) {
      items[productIdx].categoryPriority[priorityIdx].priority = value;
    } else {
      items[productIdx].categoryPriority = [
        ...(products[productIdx].categoryPriority || []),
        { categoryId: Number(categoryId), priority: value },
      ];
    }
    setProducts(items);
  };

  const submitPriority = async (itemId) => {
    try {
      setIsSubmittingById({
        ...isSubmittingById,
        [itemId]: true,
      });
      const product = products.find((i) => i._id === itemId);
      const { priority } = product.categoryPriority.find(
        (i) => i.categoryId === Number(categoryId),
      );
      await apiEshop.adminUpdateProductPriority(ppo, itemId, {
        data: { priority: Number(priority), categoryId: Number(categoryId) },
      });
      fetchProducts();
    } catch (e) {
      alert(__("Nepodarilo sa zmeniť prioritu zoradenia produktu"));
    } finally {
      setIsSubmittingById({
        ...isSubmittingById,
        [itemId]: false,
      });
    }
  };

  return (
    <Segment loading={isFetchingProducts}>
      <BasicTable
        columns={[
          { header: __("Názov") },
          { width: 140, header: __("Priorita") },
        ]}
        rows={products}
        renderRow={(i) => {
          const productPriorityItem = (i.categoryPriority || []).find(
            (item) => item.categoryId === Number(categoryId),
          );
          return [
            i.name,
            <Input
              value={productPriorityItem ? productPriorityItem.priority : 0}
              onChange={(e) => handleChangePriority(i, e.target.value)}
            >
              <input />
              <Input.Button
                onClick={() => submitPriority(i._id)}
                primary
                icon="check"
                loading={isSubmittingById[i._id]}
              />
            </Input>,
          ];
        }}
        rowKey="_id"
      />
      <Paginator
        total={total}
        limit={LIMIT}
        step={LIMIT}
        offset={offset}
        onChangeOffset={setOffset}
      />
    </Segment>
  );
};

ProductsSorter.propTypes = {
  appSpace: PropTypes.string.isRequired,
  saleschannel: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default ProductsSorter;
