import * as React from "react";
import { connect } from "react-redux";
import { organizationSelector } from "../App/selector";
import {
  setActiveAppspace,
  setApplicationInfo,
  setApps,
  setAppspaces,
} from "../App/actions";
import { withRouter } from "react-router-dom";
import AuthProvider from "@sportnet/auth-react/AuthProvider";
import Loader from "@sportnet/ui/Loader";
import SportnetApi from "../../service/apiSportnet";
import api from "../../service/apiEshop";
import paygateApi from "../../service/paygateApi";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const mapStateToProps = (state) => ({
  activeAppspace: organizationSelector(state),
});

const Authorization = ({ dispatch, ppo, children }) => {
  const [authorized, setAuthorized] = React.useState(false);

  const handleDispatch = React.useCallback(async () => {
    try {
      dispatch(setActiveAppspace({ app_space: ppo } || ""));
      const response = await SportnetApi.meAppSpaces({ expandApp: true });
      const newApps = (response?.apps || []).map((a) => {
        const appSpaceIds = (a.appspaces || []).map((as) => as.app_space);
        return { ...a.app, appSpaceIds };
      });
      dispatch(setApps(newApps));
      const appObj = (response.apps || []).find(
        (a) => a.app_id === process.env.REACT_APP_APP_ID,
      );
      if (appObj) {
        const newAppSpaces = appObj.appspaces || [];
        dispatch(setAppspaces(newAppSpaces));
      }
      const activeAppSpace = await SportnetApi.organizationPPOProfile(ppo);
      dispatch(
        setActiveAppspace({
          _id: ppo,
          name: activeAppSpace?.name || "",
          organization_name: activeAppSpace?.organization_name || "",
          logo_public_url: activeAppSpace?.logo_public_url || "",
          organization_id: activeAppSpace?.organization_id || "",
        }),
      );
      const appInfo = await SportnetApi.getPublicApp(
        process.env.REACT_APP_APP_ID || "",
      );
      dispatch(setApplicationInfo(appInfo));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, ppo]);

  return (
    <AuthProvider
      app={process.env.REACT_APP_APP_ID}
      ppo={ppo}
      onAuthorize={({ accessToken }) => {
        if (accessToken) {
          api.setToken(accessToken || "");
          SportnetApi.setToken(accessToken || "");
          paygateApi.setToken(accessToken || "");
          setAuthorized(true);
          handleDispatch();
        }
      }}
    >
      {!authorized ? (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      ) : (
        children
      )}
    </AuthProvider>
  );
};

export default connect(mapStateToProps)(withRouter(Authorization));
