import { connect } from "react-redux";
import { fetchAcl, loadCodelistOrderStatus, setSelectedOrg } from "./actions";
import { useAuth } from "@sportnet/auth-react";
import { useDispatch } from "react-redux";
import Loader from "@sportnet/ui/Loader";
import React from "react";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  @media print {
    padding-left: 0;
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  @media print {
    padding: 0;
    background-color: white;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  @media print {
    div {
      display: block;
    }
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const App = ({ acl, children, isFetchingAcl, selectedOrg }) => {
  const dispatch = useDispatch();
  const { ppo } = useAuth();

  React.useEffect(() => {
    dispatch(setSelectedOrg(ppo));
    dispatch(fetchAcl(ppo));
    dispatch(loadCodelistOrderStatus());
  }, [ppo]);

  const childrenElement = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      org: ppo,
    }),
  );

  // musi to byt tu, lebo som nevedel najst zacyklenie chyby... A hlavne tu sa az loaduje ACL...
  if (!acl || isFetchingAcl) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Div>
        <Wrapper>{selectedOrg && <Content>{childrenElement}</Content>}</Wrapper>
      </Div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { userAcl } = state;
  return {
    selectedOrg: state.selectedOrg,
    acl: userAcl.data,
    isFetchingAcl: userAcl.isFetching,
    app: state.application,
  };
};

export default connect(mapStateToProps)(App);
